







import Vue from 'vue';
import NavBar from '@/components/NavBar.vue'; // @ is an alias to /src

export default Vue.extend({
    name: 'Login',
    components: {
        NavBar
    },
});
