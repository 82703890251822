
























































































import { defineComponent, reactive } from "@vue/composition-api";
import { auth } from "@/services/auth.service";
import router from "@/router";
import { AuthCredentials } from '../types/typeDefinitions'

export default defineComponent({
    name: 'Home',
    components: {},
    setup() {
        const { verifyLogin } = auth()
        // const {globalStore} = useGlobalStorage()
        // const {getCompany} = company()

        const cred = reactive<AuthCredentials>({ email: '', password: '' })

        const login = async () => {
            if (!cred.email || !cred.password) return
            const userFound = await verifyLogin(cred)
            if (userFound) {
                // localStorage.setItem('user', JSON.stringify(userFound))
                await router.replace({ name: 'overview' })
            } else {
                Object.assign(alert, {
                    seconds: 5,
                    text: `Login Fehlgeschlagen.`,
                    variant: 'danger'
                })
            }
        }
        const alert = reactive({
            seconds: 0,
            text: '',
            variant: 'primary'
            // dismissCountdown: false
        })
        return {
            alert,
            cred,
            login,
        }
    }
});
