import { useApi } from "@/services/api.service";

const { api } = useApi()

export function fileHandling() {

    async function uploadFile(file: FormData, ticketId: string): Promise<boolean> {
        console.log(file)
        const response = await api.post('api/tickets/' + ticketId + '/file', file).catch((error) => {
            console.log(error);
            return undefined
        })
        if (!response) return false
        return true
    }

    return {
        uploadFile
    }
}
