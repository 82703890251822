import router from "@/router";


export function session() {

    const logout = () => {
        localStorage.removeItem('user')
        router.push({ name: 'home' })
    }

    return {
        logout
    }
}
