





























































































































































































































import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { ticket } from "@/services/ticket.service";
import { user } from "@/services/user.service";
import { fileHandling } from "@/services/file.service";
import router from "@/router";
import Pill from "../components/Pill.vue";
import { Comment, Ticket, AworkFile } from "@/types/typeDefinitions";

export default defineComponent({
    name: 'Ticket',
    components: {
        Pill
    },
    setup() {
        const { getTicket, createTicket, editTicket, createComment } = ticket()
        const { getUserProjects } = user()
        const isNewTicket = ref(true)
        const newComment = ref('')
        let commentList = reactive<Comment[]>([])
        const activeTicket = reactive<Ticket>({ priority: 'low' } as Ticket)
        const files = ref<File[]>([])
        const projectList = reactive<{ value: string, text: string }[]>([])

        const { uploadFile } = fileHandling()

        const ticketDetails = async () => {
            Object.assign(activeTicket, await getTicket(router.currentRoute.params.ticketid))
            if (!activeTicket.project) return router.back() //TODO ERROR MESSAGE

            projectList.push({
                value: activeTicket.projectId,
                text: activeTicket.project.name
            })
            await getComments()
            isNewTicket.value = false
        }

        const newTicket = async () => {
            const projects = await getUserProjects()
            if (projects === undefined) return router.back()

            for (const project of projects) {
                projectList.push({ value: project.id, text: project.name })
            }
            activeTicket.projectId = projectList[0].value
            isNewTicket.value = true
        }

        const getComments = async () => {
            commentList.splice(0)
            Object.assign(commentList, activeTicket.comments.reverse())
        }

        const update = async () => {
            if (router.currentRoute.params.ticketid) {
                await ticketDetails()
                isNewTicket.value = false
            } else {
                await newTicket()
                isNewTicket.value = true
            }
        }

        onMounted(async () => {
            await update()
        })

        const cancelEntry = () => {
            router.push({ name: 'overview' })
        }

        const alert = reactive({
            seconds: 0,
            text: '',
            variant: 'primary'
            // dismissCountdown: false
        })

        const editAlert = async (): Promise<boolean> => {
            delete activeTicket.userId
            if (await editTicket(activeTicket)) {
                Object.assign(alert, {
                    seconds: 5,
                    text: `Das Ticket '${activeTicket.name}' wurde erfolgreich gespeichert.`,
                    variant: 'success'
                })
                return true
            } else {
                Object.assign(alert, {
                    seconds: 5,
                    text: `Das Ticket '${activeTicket.name}' konnte nicht gespeichert werden.`,
                    variant: 'danger'
                })
                return false
            }
        }

        const uploadFiles = async (): Promise<boolean> => {
            const formData = new FormData()
            for (const file of files.value) {
                formData.set("data", file)
                if (!await uploadFile(formData, (activeTicket.id) ?? '')) return false
            }
            files.value = []
            return true
        }

        const createNewTicket = async () => {
            activeTicket.status = 'todo'
            const newTicketId = await createTicket(activeTicket)
            if (newTicketId) {
                Object.assign(alert, {
                    seconds: 7,
                    text: `Das Ticket '${activeTicket.name}' wurde erfolgreich gespeichert.`,
                    variant: 'success'
                })
                activeTicket.id = newTicketId
                return true
            } else {
                Object.assign(alert, {
                    seconds: 7,
                    text: `Das Ticket '${activeTicket.name}' konnte nicht gespeichert werden.`,
                    variant: 'danger'
                })
                return false
            }
        }

        const showUploadError = () => {
            Object.assign(alert, {
                seconds: 7,
                text: `Ticket '${activeTicket.name}' konnte gespeichert werden aber der Datei-Upload ist fehlgeschlagen.`,
                variant: 'danger'
            })
        }

        const saveEntry = async () => {
            let success
            if (isNewTicket.value) {
                success = await createNewTicket()
            } else {
                success = await editAlert()
            }
            if (!success) return

            if (!await uploadFiles()) return showUploadError()

            if (!router.currentRoute.params.ticketid && activeTicket.id) {
                await router.push({ name: 'ticket', params: { ticketid: activeTicket.id } })
            }
            await update()
        }

        const addComment = async () => {
            if (!activeTicket.id) return

            await createComment(newComment.value, activeTicket.id)
            newComment.value = ''
            await ticketDetails()

        }

        const prioOptions = [
            { value: 'low', text: 'Niedrig' },
            { value: 'mid', text: 'Mittel' },
            { value: 'high', text: 'Hoch' },
        ]

        const fields = [
            {
                key: 'comment',
                label: '',
                sortable: false
            },
        ]

        const fileFields = [
            {
                key: 'name',
                label: 'Anhänge',
                sortable: false
            },
        ]

        return {
            newComment,
            alert,
            projectList,
            activeTicket,
            addComment,
            cancelEntry,
            saveEntry,
            commentList,
            fields,
            prioOptions,
            fileFields,
            // fileList,
            isNewTicket,
            files,
        }
    }
});
