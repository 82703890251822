<template>
    <b-button pill class="center" :variant="status[pillStatus].variant">
        <!--                              v-b-popover.hover.bottom.html="getPopover">-->
        <b-icon :icon="status[pillStatus].icon" style="margin-right: 10px;"></b-icon>
        {{ status[pillStatus].text }}
    </b-button>
</template>

<script>
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
    name: "Pill",
    props: {
        pillStatus: String
    },
    setup() {
        const status = reactive(
            {

                todo: {
                    variant: 'info',
                    icon: 'circle',
                    text: 'To Do',
                },
                progress: {
                    variant: 'warning',
                    icon: 'arrow-right-circle',
                    text: 'In Arbeit'
                },
                done: {
                    variant: 'success',
                    icon: 'check-circle',
                    text: 'Erledigt'
                },
                stuck: {
                    variant: 'danger',
                    icon: 'dash-circle',
                    text: 'Blockiert'
                },
                obsolete: {
                    variant: 'secondary',
                    icon: 'trash',
                    text: 'Obsolet'
                }

            }
        )

        return {
            status
        }
    }

})
</script>

<style scoped>

</style>
