






























































































































































import { defineComponent, getCurrentInstance, reactive, ref } from "@vue/composition-api";
import { auth } from "@/services/auth.service";
import router from "@/router";

export default defineComponent({
    name: "Register",
    setup() {
        const { register } = auth()

        const { emit, ...context } = getCurrentInstance() as NonNullable<ReturnType<typeof getCurrentInstance>>;

        const inputData = reactive({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passcheck: '',
        })
        const registerUser = (refs: any) => {
            if (!checkFormValidity(refs)) return
            register(inputData)
            emit.call(context, 'login')
        }
        const alert = ref({
            seconds: 0,
            text: '',
            variant: 'primary'
            // dismissCountdown: false
        })
        const checkFormValidity = (refs: any) => {
            const valid = refs.form.checkValidity()
            if (!valid) {
                alert.value = {
                    seconds: 7,
                    text: `Nicht alle Felder ausgefüllt oder ungültige E-Mail-Adresse angegeben.`,
                    variant: 'danger'
                }
                return false
            }
            if (inputData.password !== inputData.passcheck) {
                alert.value = {
                    seconds: 7,
                    text: `Die Passworteingabe stimmt nicht überein.`,
                    variant: 'danger'
                }
                return false
            }
            return true
        }
        return {
            alert,
            inputData,
            registerUser
        }
    }
})
