import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from "@/views/Home.vue";
import Overview from "@/views/Overview.vue";
import Ticket from "@/views/Ticket.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/overview',
        name: 'overview',
        meta: {
            auth: true
        },
        component: Overview
    },
    {
        path: '/ticket/:ticketid',
        name: 'ticket',
        meta: {
            auth: true
        },
        component: Ticket
    },
    {
        path: '/newTicket',
        name: 'newTicket',
        meta: {
            auth: true
        },
        component: Ticket
    },
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    if (to.meta?.auth && !localStorage.getItem('user')) {
        next({ name: 'home' })
    } else if (to.name === 'home' && localStorage.getItem('user')) {
        next({ name: 'overview' })
    } else {
        next()
    }
})

export default router
