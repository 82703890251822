import { AuthCredentials } from "@/types/typeDefinitions";
import { useApi } from "@/services/api.service";
import axios from "axios";

const { api } = useApi()
const TOKEN_KEY = 'authKey'

let authKey = ''

export function auth() {
    async function setToken(key: string): Promise<void> {
        // console.log(key)
        await localStorage.setItem('user', key)
        await localStorage.setItem(TOKEN_KEY, key)

        authKey = key
    }

    async function verifyLogin(cred: AuthCredentials) {
        if (!cred) return
        try {
            const response = await axios.post(api.defaults.baseURL + 'api/auth/login', cred)
            if (response.data.token) {
                await setToken(response.data.token)
                return true
            } else {
                return false
            }
        } catch (error) {
            return console.log(error)
        }
    }

    async function register(cred: Record<string, unknown>) {
        if (!cred) return
        const response = await api.post('api/auth/register', cred)
        return response.data
    }

    async function getToken() {
        if (authKey !== '') {
            return authKey
        } else {
            const key = localStorage.getItem(TOKEN_KEY)
            // console.log(key)
            // const key = (await Storage.getItem({
            //     key: TOKEN_KEY
            // })).value

            if (key) {
                authKey = key
                return authKey
            } else {
                return 'error'
            }
        }
    }

    return {
        verifyLogin,
        register,
        getToken,
        setToken
    }
}
