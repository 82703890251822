







import Vue from 'vue';
import Login from "@/components/Login.vue";
import Register from "@/components/Register.vue";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
    name: 'Home',
    components: {
        Login,
        Register
    },
    setup() {
        const isRegister = ref(false)
        return {
            isRegister
        }
    }
});
