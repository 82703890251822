import { useApi } from "@/services/api.service";
import { Ticket } from "@/types/typeDefinitions";

const { api } = useApi()

export function ticket() {

    async function getTicketsOfOneUser(): Promise<Ticket[]> {
        const response = await api.get('api/tickets/user')
        return response.data
    }

    async function getTicket(ticketId: string): Promise<Ticket | undefined> {
        if (!ticketId) return
        const response = await api.get('api/tickets/' + ticketId).catch((error) => {
            console.log(error);
            return undefined
        })
        if (!response) return undefined
        console.log("TICKET", response.data)
        return response.data
    }

    // async function getTicketDetails(ticketId: string): Promise<TicketDetails | undefined> {
    //     const response = await api.get('api/tickets/' + ticketId + '/details').catch((error)=> {
    //         console.log(error);
    //         return undefined
    //     })
    //     if(!response) return undefined
    //     return response.data
    // }
    //
    // async function emptyTicketDetails(): Promise<TicketDetails | undefined> {
    //     const response = await api.get('api/tickets/emptyTicketDetails').catch((error)=> {
    //         console.log(error);
    //         return undefined
    //     })
    //     if(!response) return undefined
    //     return response.data
    // }

    async function createTicket(ticket: Ticket): Promise<string | undefined> {
        const response = await api.post('api/tickets', ticket).catch((error) => {
            console.log(error);
            return undefined
        })
        if (!response) return undefined
        console.log(response.data)
        return response.data
    }

    async function editTicket(ticket: Ticket): Promise<boolean> {
        const response = await api.put('api/tickets/' + ticket.id, ticket).catch((error) => {
            console.log(error);
            return undefined
        })
        if (!response) return false
        return true
    }

    async function createComment(comment: string, ticketId: string): Promise<boolean> {
        const response = await api.post('api/tickets/' + ticketId + '/comments', { text: comment }).catch((error) => {
            console.log(error);
            return undefined
        })
        if (!response) return false
        return true
    }

    return {
        getTicketsOfOneUser,
        createTicket,
        editTicket,
        createComment,
        getTicket,
    }
}
