import { useApi } from "@/services/api.service";

const { api } = useApi()

export function user() {
    // async function getUser(userId: string) {
    //     const response = await api.get('api/users/' + userId)
    //     return response.data
    // }
    // async function getUserId() {
    //     const response = await api.get('api/users/userId')
    //     return response.data
    // }

    async function getUserProjects() {
        const response = await api.get('api/users/userProjects').catch((error) => {
            console.log(error);
            return undefined
        })
        if (!response) return undefined
        return response.data
    }

    return {
        getUserProjects,
        // getUserId,
        // getUser,
    }
}
