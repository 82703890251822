import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import router from "@/router";

import { auth } from "@/services/auth.service";


const API_URL = process.env.VUE_APP_API_URL
const { getToken, setToken } = auth()

export function useApi() {
    const api: AxiosInstance = Axios.create({
        baseURL: API_URL,
    }); /* = reactive({} as AxiosInstance)*/

    function init() {
        // const apiTemp = Axios.create({
        //     baseURL: API_URL,
        // });

        api.interceptors.request.use(async function (config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
            // Do something before request is sent
            config.headers["Authorization"] = "Bearer " + await getToken();
            return config;
        });

        api.interceptors.response.use(async function (response: AxiosResponse): Promise<AxiosResponse> {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            if (!response.headers.authorization) return response
            await setToken(response.headers.authorization)
            return response;
        }, function (error: { response: { status: number; }; }): Promise<never> {
            if (error.response.status === 401) {
                localStorage.removeItem('user')
                router.replace({ name: 'home' })
            }
            return Promise.reject(error);
        });
    }

    //
    // async function getApiUrl() {
    //     if (API_URL !== '') {
    //         return API_URL
    //     } else {
    //         const url = localStorage.getItem(WORKSPACE_KEY)
    //
    //         if (url) {
    //             API_URL = url
    //             return API_URL
    //         } else {
    //             return ''
    //         }
    //     }
    // }

    // async function setWorkspace() {
    //     // apiUrl = `https://api.${workspaceName}.podadmin.de/api/`
    //     API_URL = 'http://localhost:3001/'
    //
    //     localStorage.setItem(WORKSPACE_KEY, API_URL)
    //
    //     api.defaults.baseURL = API_URL
    //     // console.log( api.defaults.baseURL)
    // }

    init();

    return {
        api,
        // setWorkspace
    }
}
