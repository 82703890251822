































import Vue from 'vue';
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from "@/router";
import { defineComponent, ref } from "@vue/composition-api";
import { session } from "@/composables/Session";

export default defineComponent({
    name: 'HelloWorld',
    props: {
        msg: String,
    },
    setup() {
        const { logout } = session()
        const activeView = ref('Home')
        const useRouting = (page: string) => {
            router.push({ name: page })
            // activeView.value = page
        }

        return {
            useRouting,
            logout,
            activeView,
        }
    }
});
