
















































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import VueRouter from "vue-router";
import { ticket } from '@/services/ticket.service'
import { Ticket } from "@/types/typeDefinitions";
import Pill from "@/components/Pill.vue";

export default defineComponent({
    name: 'Home',
    components: {
        Pill,
    },
    setup() {
        const { getTicketsOfOneUser } = ticket()

        const openTicket = async (router: VueRouter, item: Ticket) => {
            if (!item.id) return // TODO ERROR MESSAGE
            await router.push({ name: 'ticket', params: { ticketid: item.id } })
        }

        const ticketList = reactive<Ticket[]>([])
        const update = async () => {
            const tempList = await getTicketsOfOneUser()
            ticketList.push(...(tempList));
        }

        onMounted(async () => {
            await update()
        })

        const fields = [
            {
                key: 'status',
                label: '',
                sortable: false,
                thStyle: 'min-width: 150px; width: 150px;'
            },
            {
                key: 'name',
                label: 'Ticket',
                sortable: true,
            },
            {
                key: 'project.name',
                label: 'Projekt',
                sortable: true
            },
        ]
        return {
            openTicket,
            ticketList,
            fields,
            filter: '',
            filterOn: [],

        }
    }
});
